<template>
	<div class="bordered mt16">
		<Title>师资管理</Title>
		<TotalAnalysis style="marginBottom: 0" :value="numberFun"></TotalAnalysis>
		<div class="m_xares">
			<div class="line" v-for="(v, i) in essenFun" :key="i">
				<countTo :startVal="0" :endVal="v.value" :duration="1000" class="value"></countTo>
				<p>{{ v.name }}</p>
			</div>
		</div>
		<BaseEchart :option="option" style="width: 100%; height: 254px"></BaseEchart>
	</div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import Title from '../components/Title.vue';
import countTo from 'vue-count-to';
import BaseEchart from '@/components/BaseEchart';
import TotalAnalysis from '../components/TotalAnalysis.vue'
import { mutBarChart } from '../common';
import { randomByLevel } from '@/views/homePage/common';
import { CountAnimationDuration } from '@/views/homePage/common';

export default {
	components: { Title, countTo, TotalAnalysis, BaseEchart},
	data() {
		return {
      CountAnimationDuration,
			number: 60524,
			essenArr: [{value: 1025, name: '教师总数'}, {value: 2510, name: '名师讲堂'}, {value: 1836, name: '中医类'}],
			option: null
		};
	},
	computed: {
		...mapState('mapData', ['mapLevel', 'mapDataArr', 'addressInfo']),
    numberFun(){
      return this.number / (this.mapLevel + 1)
    },
    essenFun(){
      return this.essenArr.map(s => {
        return {
          ...s,
          value: s.value / (this.mapLevel + 1)
        }
      })
    }
	},
	watch: {
    mapDataArr: {
      handler() {
        const dataX = this.mapDataArr.map((d) => {
          return { name: d.regionName, value: randomByLevel({level: this.mapLevel}) };
        })
        this.option = mutBarChart({ 
					leArr: ['教师总数', '名师讲堂', '中医类'],
					xData: dataX.map((d) => d.name), 
					yFirst: dataX.map((d) => Math.floor(d.value*7)), 
					ySecond: dataX.map((d) => Math.floor(d.value*10)),
					yThird: dataX.map((d) => Math.floor(d.value * 8.5)) 
				});
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/theme/bigScreen.scss';
.bordered{
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
}
.m_xares{
	display: flex;
	padding: 16px 8px;
	text-align: center;
	.line{
		flex: 1;
		height: 70px;
		background: rgba(28,135,255,0.25);
		margin: 0 8px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	p{
		font-size: 12px;
		color:#fff;
		padding-top: 4px;
	}
}
.value {
	color: $main-text;
	font-size: 20px;
}
</style>
