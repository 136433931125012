<template>
  <div class="bordered">
    <Title>教学资源管理</Title>
    <div class="m_xcourse">科目分类<countTo class="column2" :startVal="0" :endVal="cationFun" :duration="CountAnimationDuration"></countTo></div>
    <div class="m_xcourse">总课时（分）<countTo class="column2" :startVal="0" :endVal="periodFun" :duration="CountAnimationDuration"></countTo></div>
    <h3 class="m_xhead">课件资源库总大小：<em>{{ textFun }}G</em></h3>
    <div class="m_xweath">
      <div class="rows">
        <img src="../assets/cu1.png" class="icon" />
        <div class="m_xare">
          <h4>文本类<div><countTo :startVal="0" :endVal="textFirst" :duration="CountAnimationDuration"></countTo>G</div></h4>
          <div class="bg"><i :style="{width: textFirst + '%'}"></i></div>
        </div>
      </div>
      <div class="rows">
        <img src="../assets/cu2.png" class="icon" />
        <div class="m_xare">
          <h4>视频类<div><countTo :startVal="0" :endVal="textSec" :duration="CountAnimationDuration"></countTo>G</div></h4>
          <div class="bg"><i :style="{width: textSec + '%'}"></i></div>
        </div>
      </div>
      <div class="rows">
        <img src="../assets/cu3.png" class="icon" />
        <div class="m_xare">
          <h4>试卷类<div><countTo :startVal="0" :endVal="textThree" :duration="CountAnimationDuration"></countTo>G</div></h4>
          <div class="bg"><i :style="{width: textThree + '%'}"></i></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Title from '../components/Title.vue'
import countTo from 'vue-count-to';
import { CountAnimationDuration } from '@/views/homePage/common';
export default {
  components: {Title, countTo},
  data() {
    return {
      CountAnimationDuration,
      cation: 40758,
      period: 21562,
      text: 245,
      text1: 85,
      text2: 70,
      text3: 90,
    }
  },
  computed: {
		...mapState('mapData', ['mapLevel', 'mapDataArr', 'addressInfo']),
    cationFun(){
      return Math.floor(this.cation / (this.mapLevel + 1))
    },
    periodFun(){
      return Math.floor(this.period / (this.mapLevel + 1))
    },
    textFirst() {
      return Math.floor(this.text1 / (this.mapLevel + 1))
    },
    textSec() {
      return Math.floor(this.text2 / (this.mapLevel + 1))
    },
    textThree() {
      return Math.floor(this.text3 / (this.mapLevel + 1))
    },
    textFun(){
      return Math.floor(this.text / (this.mapLevel + 1))
    }
	}
}
</script>
<style lang="scss" scoped>
.bordered{
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
}
.m_xcourse{
  height: 54px;
  background: rgba(28, 135, 255, .25);
  margin: 18px;
  padding: 0 18px;
  font-size: 14px;
  color:#fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .column2{
    color:#00FFFF;
    font-size: 26px;
  }
}
.m_xhead{
  font-size: 16px;
  padding: 0 18px 18px;
  color:#fff;
  display: flex;
  align-items: center;
  em{
    font-size: 20px;
    font-style: normal;
  }
}
.m_xweath{
  padding:0 18px;
  .rows{
    overflow: hidden;
    margin-bottom: 30px;
  }
  .icon{
    width: 48px;
    height: 48px;
    float: left;
  }
}
.m_xare{
  margin-left: 62px;
}
.m_xare h4{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color:#fff;
}
.m_xare .bg{
  background: #16356F;
  margin-top: 12px;
  i{
    display: block;
    height: 14px;
    background: linear-gradient(90deg, #72E4F3 0%, #4AAEFF 100%);
    opacity: 0.89;
  }
}
</style>