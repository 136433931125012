<template>
  <div class="w100 mt16 bordered">
    <div class="title w100">
      <div class="titleWrapper">
        <div class="indicator"></div>
        培训扩充趋势
      </div>
    </div>
    <BaseEchart :option="option" class="mt8" style=" width: 100%; height: 123px"></BaseEchart>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import BaseEchart from '@/components/BaseEchart';
export default {
  components: { BaseEchart },
  data() {
    return {
      option: null,
      arry : [90, 100, 200, 100, 110, 280, 150, 260, 160]
    };
  },
  mounted() {
    this.option = {
      axisLabel: {
        color: '#fff',
        fontSize: 10,
      },
      grid: {
        left: 40,
        right: 40,
        top: 8,
        bottom: 32,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        axisLabel: {
          interval: 0,
        },
        splitLine: {
          show: true,
          lineStyle: {
            opacity: 0.2,
            type: [5, 5],
            dashOffset: 0,
            shadowBlur: 0,
          },
        },
        data: ['传染病', '食物中毒', '职业中毒', '其他中毒事件', '环境卫生', '免疫接种', '群体性不明原因', '医疗机构内感染', '放射性卫生'],
      },
      yAxis: {
        type: 'value',
        splitNumber: 4,
        splitLine: {
          show: true,
          lineStyle: {
            opacity: 0.2,
            type: [5, 5],
            dashOffset: 0,
            shadowBlur: 0,
          },
        },
      },
      series: [
        {
          data: this.arry,
          type: 'line',
          smooth: true,
          symbol: 'none',
          lineStyle: {
            color: '#3BABF0',
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: '#0D3155', // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(0, 124, 190, 0.1)', // 100% 处的颜色
                },
              ],
            },
          },
        },
      ],
    };
  },
  computed: {
		...mapState('mapData', ['mapLevel']),
	},
  watch: {
    mapLevel: {
      handler() {
        let arry = this.arry.map((v) => {
          return v / (this.mapLevel + 1) ;
        })
        this.option = {
          axisLabel: {
            color: '#fff',
            fontSize: 10,
          },
          grid: {
            left: 40,
            right: 40,
            top: 8,
            bottom: 32,
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            axisLabel: {
              interval: 0,
            },
            splitLine: {
              show: true,
              lineStyle: {
                opacity: 0.2,
                type: [5, 5],
                dashOffset: 0,
                shadowBlur: 0,
              },
            },
            data: ['传染病', '食物中毒', '职业中毒', '其他中毒事件', '环境卫生', '免疫接种', '群体性不明原因', '医疗机构内感染', '放射性卫生'],
          },
          yAxis: {
            type: 'value',
            splitNumber: 4,
            splitLine: {
              show: true,
              lineStyle: {
                opacity: 0.2,
                type: [5, 5],
                dashOffset: 0,
                shadowBlur: 0,
              },
            },
          },
          series: [
            {
              data: arry,
              type: 'line',
              smooth: true,
              symbol: 'none',
              lineStyle: {
                color: '#3BABF0',
              },
              areaStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: '#0D3155', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(0, 124, 190, 0.1)', // 100% 处的颜色
                    },
                  ],
                },
              },
            },
          ],
        };
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/theme/bigScreen.scss';
.bordered{
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
}
.title {
  line-height: 32px;
  padding-left: 16px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .titleWrapper {
    display: flex;
    align-items: center;
  }
  .numBoard {
    display: flex;
    gap: 24px;
    font-size: 14px;
    .blue {
      font-weight: bold;
      margin-left: 6px;
      font-size: 16px;
      color: $main-text;
    }
    .red {
      font-weight: bold;
      margin-left: 6px;
      font-size: 16px;
      color: #FF0000;
    }
  }
  .indicator {
    display: inline-block;
    height: 16px;
    width: 4px;
    margin-right: 8px;
    background-image: linear-gradient(#308fcc, #224abe);
  }
}
</style>
